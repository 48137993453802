import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      style={{
        margin: "0 auto",
        maxWidth: "1080px",
        padding: "1rem",
        minHeight: "800px",
      }}
    >
      <h1>Nichts gefunden</h1>
      <Link to="/">Zurück zur Startseite!</Link>
    </div>
  </Layout>
)

export default NotFoundPage
